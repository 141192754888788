#mainlandingpage{
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;
  top: 0px;
left: 0px;
width: 1200px;
height: 90%;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
opacity: 1;
}

#enter-button{
top: 520px;
left: 10px ;
width: 105px;
height: 50px;
border-radius: 10px;
border-width: 2px;
border-color: #006238;
/* UI Properties */
text-align: left;
font: normal normal normal 32px/39px Verdana;
letter-spacing: 0px;
color: #006238;
opacity: 1;
}
#tenxteam{
top: 385px;
left: 1126px;
width: 351px;
height: 85px;
/* UI Properties */
text-align: left;
font: normal normal normal 64px/85px Palatino;
letter-spacing: 0px;
color: #006238;
opacity: 1;
}
#imageoflandingpage{
left: 400px;
width: 600px;
height: 790px;
/* UI Properties */
background: transparent url('./images/lens-1418954.png') 0% 0% no-repeat padding-box;
border: 1px solid #707070;
opacity: 1;
}
#page-nav{
  height: 54px;
  background-color:white;
  border: 1px solid #ccc;
  padding: 0 30px;
}
.header-img-container-first{
  height: 80px;
  width: 500px;
  float: left;
  background: transparent url('./images/logoinstaclone.jpg') 0% 0% no-repeat padding-box;
}
.header-img-container-second{
  height: 80px;
  width: 50px;
  float: right;
  background: transparent url('./images/camera.png') 0% 0% no-repeat padding-box;
} 
.form-container{
  width: 500px;
  height: 200px;
  margin: 40px auto;
  border: 1px solid grey;
  padding: 20px 20px;

}
.form-container input{
  padding: 10px;
  margin-bottom: 15px;
  width: 80%;
}
.form-container button{
  padding: 10px;
  margin-left: 5px;
  border-color: black ;
  background-color:#F0F0F0;
  color: black;
  border: none;
  cursor: pointer;
}
.form-container .special{
  width: 200px
}
.form-container #postButton{
  padding: 10px;
  margin-left: 220px;
  margin-left: 220px;
  cursor: pointer;
}
#description{
  width: 470px;
}
#post-view{
  top: 200px;
  left: 600px;
  width: 800px;
  height: 700px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
}
#post-name{
  top: 220px;
left: 632px;
width: 58px;
height: 31px;
text-align: left;
font: normal normal bold 28px/32px Helvetica;
letter-spacing: 0px;
color: #000000;
opacity: 1;
}
#author-address{
  top: 251px;
left: 632px;
width: 123px;
height: 29px;
/* UI Properties */
text-align: left;
font: normal normal normal 24px/29px Verdana;
letter-spacing: 0px;
color: #707070;
opacity: 1;
}
#post-image{
  top: 300px;
left: 600px;
width: 800px;
height: 400px;
/* UI Properties */
background: transparent url('') 0% 0% no-repeat padding-box;
border: 1px solid #707070;
opacity: 1;
}
#description2{
  top: 817px;
left: 632px;
width: 450px;
height: 31px;
/* UI Properties */
text-align: left;
font: normal normal bold 28px/32px Helvetica;
letter-spacing: 0px;
color: #000000;
opacity: 1;
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
